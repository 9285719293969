import React from 'react'
import { StoryblokComponent } from 'gatsby-source-storyblok'

const NotFoundPageContent = ({ siteConfig }) => {
  const components =
    siteConfig.notFoundBody?.map((blok, index) => {
      let finalBlok = {
        ...blok,
        sectionIndex: index,
        isFirstSection: index === 0,
      }

      return <StoryblokComponent blok={finalBlok} key={finalBlok._uid} />
    }) || []

  return <main>{components}</main>
}

export default NotFoundPageContent
