import * as React from 'react'
import { graphql } from 'gatsby'

import { getLocaleContentFromNodes } from '../utils'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import NotFoundPageContent from '../components/NotFoundPageContent'

const NotFoundPage = ({ pageContext, location, data }) => {
  const siteConfig = getLocaleContentFromNodes({
    nodes: data.siteConfigurations?.nodes,
    language: pageContext.language,
  })
  const context = {
    ...pageContext,
    siteConfig,
  }

  return (
    <Layout context={context} location={location} floatingSiteNavigation={true}>
      <NotFoundPageContent siteConfig={siteConfig} />
    </Layout>
  )
}

export default NotFoundPage

export const Head = ({ pageContext, location, data }) => {
  const siteConfig = getLocaleContentFromNodes({
    nodes: data.siteConfigurations?.nodes,
    language: pageContext.language,
  })

  const seo = {
    title: siteConfig?.notFoundTitle || 'Page not found',
  }

  return <Seo location={location} seo={seo} />
}

export const query = graphql`
  query ($language: String! = "en") {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    siteConfigurations: allStoryblokEntry(
      filter: {
        field_component: { eq: "SiteConfiguration" }
        lang: { in: ["default", $language] }
      }
    ) {
      nodes {
        lang
        content
      }
    }
  }
`
